h1, h2, h3, h4, h5, h6 {
    clear: both;
    font-weight: normal;
}

del {
    text-decoration: line-through;
}

a img {
    border: none;
}

.btn-primary {
    background-color: #8B4513;
    border-color: #d6ba94b3;
}

.btn-primary:hover, .btn-primary:focus {
    background-color: #d6ba94b3;
    border-color: #8B4513;
}

.hide {
    display: none !important;
}

.popover {
    max-width: 550px;
    max-height: 50%;
    overflow: scroll;
}

.popDesc span {
    text-indent: 0.25in;
    color: #8B4513;
    font-weight: bold;
    position: absolute;
}

#filterData {
    font-size: 18px;
}

/* =Base Layout
-------------------------------------------------------------- */
body {
    font-family: 'Raleway', sans-serif;
    font-weight: normal;
    color: #8B4513;

    background: #8B4513 url("../img/bkgrnd.png") no-repeat fixed 0 0;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
}

header {
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(139, 69, 19, 0.72);
    color: white;

    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    -ms-transition: height 0.3s;
    -o-transition: height 0.3s;
    transition: height 0.3s;
}

header #logo, header h1 {
    cursor: pointer;
}

header #logo {
    height: 150px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    -webkit-background-size: contain;
    -o-background-size: contain;
    -moz-background-size: contain;
    background-size: contain;
    background: url('../img/logo.png') top center no-repeat;
    opacity: .4;
    margin: 12px auto;
}

header.smaller {
    height: 106px;
}

header.smaller #logo {
    height: 75px;
    background-size: contain;
}

h1, .h1 {
    font-size: 2rem;
    margin-top: 10px;
    margin-bottom: 12px;
}

h2, .h2 {
    font-size: 1.5rem;
}

h3, .h3 {
    font-size: 1.25rem;
    margin: auto;
}

h4, .h4 {
    font-size: 1rem;
    margin: auto;
}

h5, .h5 {
    font-size: .75rem;
}

h6, .h6 {
    font-size: .5rem;
}

a:hover {
    color: inherit;
    font-weight: bold;
}

blockquote {
    padding: 0 20px;
    margin: 0;
    font-size: 13px;
    border-left: 5px solid #8B4513;
}

.full-height {
    height: 100vh;
}

.flex-default {
    display: flex;
    justify-content: center;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.site-motto {
    padding-top: 19px;
}

header.smaller .site-motto {
    padding-top: 12px;
}

.tag-line, .site-motto {
    line-height: normal;
}

.credits {
    font-size: small;
    text-indent: 14rem;
}

footer .credits {
    font-size: small;
    text-indent: 0;
    margin: 5px;
}

a {
    color: #8B4513;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: transparent;
}

.links > a {
    padding: 0 25px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.navbar-header, .navbar li {
    min-width: 100%;
}

nav.sidebar ul.nav {
    margin-top: 16px;
    width: 100%;
}

/* =Content area
-------------------------------------------------------------- */
.p1, .p2 {
    text-indent: .25in;
    margin-left: 16px;
    margin-right: 16px;
}

.card-body {
    padding: 6px;
    background-color: #d6ba94b3;
}

.card {
    text-align: left;
    border-color: transparent;
    border-radius: .25rem;
}

.card form {
    padding: 12px;
}

#hymn_search_hymnsSearch {
    margin-left: 6px;
}

body#search #accordion .card:first-of-type {
    border-top: 1px solid;
    border-radius: 6px 6px 0 0;
}

.search-form,
#accordion .card {
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
}

.search-form {
    border-radius: 0 0 6px 6px;
}

h5.card.results-container {
    border: 1px solid;
    padding: 8px;
}

audio#player {
    background-color: #d6ba9400 !important;
}

#image_container img {
    width: 100%;
}

aside.sticky {
    position: sticky;
    top: 150px;
}

aside.sticky.higher {
    top: 100px;
}

.content {
    text-align: center;
}

.dropdown-menu > li {
    font-weight: bold;
    color: #8B4513;
}

.navbar .navbar-header.card.h3 {
    border: none;
}

.navbar h5.navbar-header {
    font-size: 16px;
}

.navbar h5.navbar-header, li.active a, .navbar-nav .active, .navbar-nav > .open, .dropdown-menu > .active {
    background-image: -webkit-linear-gradient(top, rgba(139, 69, 19, 0.8) 0, rgba(139, 69, 19, 0.6) 100%);
    background-image: -o-linear-gradient(top, rgba(139, 69, 19, 0.8) 0, rgba(139, 69, 19, 0.6) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom,
    from(rgba(139, 69, 19, 0.8)), to(rgba(139, 69, 19, 0.6)));
    background-image: linear-gradient(to bottom, rgba(139, 69, 19, 0.8) 0, rgba(139, 69, 19, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(139, 69, 19, 0.8)',
    endColorstr='rgba(139, 69, 19, 0.6)', GradientType=0);
    background-repeat: repeat-x;

    color: #ffffff !important;
    font-weight: bold;
    -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
    box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
}

.navbar-nav li:focus, .navbar-nav li:hover,
.dropdown-menu > li > a:focus, .dropdown-menu > li:hover,
.dropdown-menu > .active:focus, .dropdown-menu > .active:hover, .hover {
    background-image: -webkit-linear-gradient(top, rgba(139, 69, 19, 0.4) 0, #ffffff 100%);
    background-image: -o-linear-gradient(top, rgba(139, 69, 19, 0.4) 0, #ffffff 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(139, 69, 19, 0.4)), to(#ffffff));
    background-image: linear-gradient(to bottom, rgba(139, 69, 19, 0.4) 0, #ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(139, 69, 19, 0.4)', endColorstr='#ffffff',
    GradientType=0);
    background-repeat: repeat-x;

    color: #8B4513 !important;
    font-weight: bold;
    -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
    box-shadow: inset 0 3px 9px rgba(0, 0, 0, .075);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link,
.dropdown-menu > .active, .dropdown-menu > .active:focus, .dropdown-menu > .active:hover {
    background-color: #8B4513;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
    border: 1px solid #8B4513;
    background: rgba(139, 69, 19, 0.8);
}

.navbar-nav.sidebar > li > a:not(.active) {
    color: #8B4513;
    font-weight: bold;
}

.dropdown-menu {
    border: 1px solid #8B4513;
}

.dropdown-divider {
    border-top: 1px solid #8B4513;
}

ul.dropdown-menu.show li.active a {
    color: white;
}

section#main_content div.card:first-of-type {
    border-top: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

h3.card.page-title {
    border-radius: 6px 6px 0 0;
}

h3.page-title,
#hymn-list #accordion .card {
    border: solid 1px;
}

nav.navbar {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

nav .card {
    margin-top: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.box {
    display: flex;
    justify-content: space-between;
}

.accordion > .card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

h2.page-title,
.card-header {
    background-image: -webkit-linear-gradient(top, rgba(139, 69, 19, 0.8) 0, rgba(139, 69, 19, 0.6) 100%);
    background-image: -o-linear-gradient(top, rgba(139, 69, 19, 0.8) 0, rgba(139, 69, 19, 0.6) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(139, 69, 19, 0.8)), to(rgba(139, 69, 19, 0.6)));
    background-image: linear-gradient(to bottom, rgba(139, 69, 19, 0.8) 0, rgba(139, 69, 19, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(139, 69, 19, 0.8)', endColorstr='rgba(139, 69, 19, 0.6)', GradientType=0);
    background-repeat: repeat-x;

    margin-top: 1px;
    color: white;
    font-weight: bold;
    width: 100%;
    padding: 0.75rem 1.25rem;
}

.ui-accordion .ui-accordion-content {
    height: 100% !important;
}

h2.page-title .input-group-text {
    padding: 0.4rem 0.8rem;
    font-size: 0.95rem;
    line-height: calc(0.5em + 0.5rem + 2px);
}

h2.page-title form {
    padding: 0;
}

#accordion .hymn-list-header, .page-title {
    padding: .25em .25em .25em .7em;
}

#accordion .hymn-list-header .btn:focus, .btn.focus {
    box-shadow: none;
}

#accordion .hymn-list-header .h4 {
    color: #8B4513;
    background-color: #ffffff;
}

#accordion .hymn-list-header:hover,
#accordion .hymn-list-header.active {
    background-color: rgba(139, 69, 19, 0.4);
}

#accordion .hymn-list-header:hover .h4,
#accordion .hymn-list-header.active .h4 {
    background-color: transparent;
    color: #ffffff;
}

a.hymn-info-link {
    display: block;
    padding: 6px;
}

.hymn-info .row {
    padding: 6px 0;
}

.modal-header.row {
    margin: 0;
}

.hymn-info .row:last-of-type {
    padding-bottom: 12px;
}

.hymn-info hr {
    border-bottom: 1px solid #8B4513;
}

a:hover span.small,
a:hover span.medium{
    font-weight: 700;
}

a span.medium {
    font-size: 95%;
}

textarea.hymn-text {
    width: 100%;
    height: 75ch;
}

textarea.autoExpand {
    width: 100%;
    resize: none;
    border: none;
}

.hymn-score {
    text-align: center;
    cursor: pointer;
}

.modal-content {
    height: 85vh;
}

.modal-body {
    word-break: break-word;
}

.modal-header {
    padding: 3px;
    background-color: #d6ba94b3;
}

.modal-header h5 {
    text-align: center;
}

.modal-title {
    text-align: center;
}

.modal-dialog .modal-body {
    height: 85%;
}

.modal-dialog .authcomp.modal-body {
    overflow: scroll;
    margin: 3px;
}

.modal-body textarea {
    width: 100%;
    height: 100%;
}

#largeModal .modal-body {
    position: relative;
    padding: inherit;
    height: 100%;
}

input, button, select, optgroup, textarea {
    margin: inherit;
    max-width: 100%;
}

input#myInput {
    max-width: 120px;
}

h3#last_ten {
    font-size: 16px;
    margin: 0;
}

footer, section.card.last-ten {
    margin-top: 8px;
}

.scroller {
    margin: 10px 15px;
    font-weight: bold;
    line-height: 1.5;
    font-size: small;
}

.scroller li:hover,
.scroller li:hover * {
    background-color: black;
    color: white;
}

.comment-author {
    text-align: right;
    font-size: 14px;
    font-style: italic;
}

textarea#user_comment_form_comment {
    width: 100%;
    height: 100px;
}

#policy {
    padding-left: 18px;
    padding-right: 18px;
}

#policy h2 {
    font-size: 1.4em;
    line-height: 1.1;
    padding: 14px 0 0;
}

#policy .accordion-heading {
    font-size: 1.2em;
}

#admin_dashboard .user-comments-heading {
    font-size: 18px;
}

#admin_dashboard .user-comments a {
    font-weight: bold;
    text-decoration: underline;
}

#admin_dashboard_form_comment {
    width: 100%;
}

/* =Page - Artist/Vendor Crud
-------------------------------------------------------------- */
#hymn-artist-list .page-title {
    margin-bottom: 0 !important;
}

/* =Page - Hymns Crud
-------------------------------------------------------------- */
#admin-hymns-edit .page-title,
#admin-hymns-show .page-title {
    margin-bottom: 0 !important;
}

#admin-hymns-new textarea,
#admin-hymns-edit textarea {
    width: 100%;
    height: 50ch;
}

/* =Page - Hymn Category Crud
-------------------------------------------------------------- */
#hymn-category-edit #hymn_category_hymns input[type=checkbox],
#hymn-category-new #hymn_category_hymns input[type=checkbox] {
    margin-right: 6px;
}


/* =Extras
-------------------------------------------------------------- */
.clearfix:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
}

/* =Media Queries
-------------------------------------------------------------- */
@media all and (max-width: 660px) {
    /* =Header
    -------------------------------------------------------------- */
    header #logo {
        display: block;
        float: none;
        margin: 0 auto;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }

    header nav {
        display: block;
        float: none;
        height: 50px;
        text-align: center;
        margin: 0 auto;
    }

    header nav a {
        line-height: 50px;
        margin: 0 10px;
    }

    header.smaller {
        height: 125px;
    }

    header.smaller #logo {
        height: 40px;
        line-height: 40px;
        font-size: 30px;
    }

    header.smaller nav {
        height: 35px;
    }

    header.smaller nav a {
        line-height: 35px;
    }

    header.smaller #logo {
        height: 40px !important;
        background-size: cover;
    }

    header .site-motto {
        display: none;
    }

    aside.sticky {
        overflow: scroll;
    }
}

@media all and (max-width: 600px) {
    .container {
        width: 100%;
    }

    #info-bar a {
        display: block;
    }

    #info-bar span.all-tutorials,
    #info-bar span.back-to-tutorial {
        width: 100%;
    }

    #info-bar span.all-tutorials,
    #info-bar span.back-to-tutorial {
        float: none;
        text-align: center;
    }

    #info-bar span.all-tutorials {
        border-bottom: solid 1px #8B4513;
    }
}
